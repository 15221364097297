import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto key fingerprint`}</strong>{` -- print the fingerprint of a public key`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto key fingerprint <key-file>
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto key fingerprint`}</strong>{` prints the fingerprint of a public key. The
fingerprint of a private key will be only based on the public part of the
key.`}</p>
    <p>{`By default the fingerprint calculated is the SHA-256 hash with raw Base64 encoding
of the ASN.1 BIT STRING of the subjectPublicKey defined in RFC 5280.`}</p>
    <p>{`Using the flag `}<strong parentName="p">{`--ssh`}</strong>{` the fingerprint would be based on the SSH encoding of
the public key.`}</p>
    <p>{`Note that for certificates and certificate request, the fingerprint would be
based only on the public key embedded in the certificate. To get the certificate
fingerprint use the appropriate commands:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate fingerprint <x509-crt|x509-csr>
$ step ssh fingerprint <ssh-crt>
`}</code></pre>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`key-file`}</inlineCode>{`
Path to a public, private key, certificate (X.509 and SSH) or
certificate request.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--sha1`}</strong>{`
Use the SHA-1 hash with hexadecimal format. The result will be equivalent to the Subject Key Identifier in a X.509 certificate.`}</p>
    <p><strong parentName="p">{`--ssh`}</strong>{`
Use the SSH marshaling format instead of X.509.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing passphrase to decrypt a private key.`}</p>
    <p><strong parentName="p">{`--raw`}</strong>{`
Print the raw bytes instead of the fingerprint. These bytes can be piped to a different hash command.`}</p>
    <p><strong parentName="p">{`--format`}</strong>{`=`}<inlineCode parentName="p">{`format`}</inlineCode>{`
The `}<inlineCode parentName="p">{`format`}</inlineCode>{` of the fingerprint, it must be "hex", "base64", "base64-url", "base64-raw", "base64-url-raw" or "emoji".`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Print the fingerprint of a public key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key fingerprint pub.pem
`}</code></pre>
    <p>{`Print the fingerprint of the public key using the SSH marshaling:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key fingerprint --ssh pub.pem
`}</code></pre>
    <p>{`Print the fingerprint of the key embedded in a certificate using the SHA-1 hash:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key fingerprint --sha1 cert.pem
`}</code></pre>
    <p>{`Print the same fingerprint for a public key, a private key and a
certificate all of with the same public key.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key fingerprint id_ed25519
$ step crypto key fingerprint id_ed25519.pub
$ step crypto key fingerprint id_ed25519-cert.pub
`}</code></pre>
    <p>{`Print the fingerprint of the key using an external tool:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key fingerprint --raw pub.pem | md5sum
`}</code></pre>
    <p>{`Print the fingerprint of the public key of an encrypted private key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key fingerprint --password-file pass.txt priv.pem
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      